import React from 'react'
import {BsTwitter, BsInstagram} from 'react-icons/bs'
import {FaLinkedinIn, FaGithub} from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <a href='https://www.linkedin.com/in/calebbuxton/' target="_blank" rel='noreferrer'>
          <div>
              <FaLinkedinIn/>
          </div>
        </a>
    </div>
  )
}

export default SocialMedia